import { useEffect, useState, useCallback } from "react";
import MediaQuery from 'react-responsive';
import { NavDropdown, Nav, Navbar, Modal, Button } from "react-bootstrap";
import { BorrowerLeadGenerationTool } from "borrower-lead-generation-tool";
import UserWayAccessibilityMenu from "./UserWayAccessibilityMenu/index";
import '../css/Header.css';

export default function Header() {
  const imgCDNURL = process.env.REACT_APP_CDN_IMAGES;
  const [scrolled, setScrolled] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  // stores the input field reference in a way that allows us to conditionally render
  // content or scroll to the element on button click.
  const [node, setNode] = useState(null);
  const [navbar, setNavbar] = useState(false);

  const changebackground = () => {
    if (window.scrollY >= 10) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };


  window.addEventListener('scroll', changebackground);


  // watches for changes in the form.
  const formRef = useCallback((node) => {
    // node refers to the name input field in the BorrowerLeadGenerationTool
    setNode(node);
  }, []);


  const handleCloseContactModal = () => setShowContactModal(false);
  const handleShowContactModal = () => setShowContactModal(true);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
    }
    else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
  })

  let x = [];
  if (scrolled) {
    x.push('scrolled');
  }

  return (
    <div className="col-sm-12 header-container">
      <Navbar expand="lg" fixed="top"
        variant="dark" className={x.join(" ") + " navbar-fixed"} id={navbar ? 'navbar active' : 'navbar'}>

        <Navbar.Brand>
          {/* Renders the components of tablet and desktop view */}
          <div className="d-flex">
            <MediaQuery minWidth={601}>
              <a target="_blank" href="https://www.swmc.com/">
                <img
                  className="headerlogo ps-2"
                  src={"https://resources.swmc.com/swmc-images/sunwest-trust-white-logo.png"}
                  alt="sun west logo"
                /></a>
            </MediaQuery>
            {/* Renders the components of mobile view */}
            <MediaQuery maxWidth={600}>
              <a target="_blank" href="https://www.swmc.com/">
                <img
                  className="headerlogo ps-2"
                  src="./images/mobile/group@2x.svg"
                  alt="swmclogo"
                /></a>
            </MediaQuery>
          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" className="me-2" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto alignR">
            <NavDropdown
              title="Home"
              id="basic-nav-dropdown"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item href="https://www.swmc.com/our-history" target="_blank">
                Our History
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/why-sunwest" target="_blank">
                Success Stories
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/careers" target="_blank">
                Careers
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/contact-us" target="_blank">
                Contact Us
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title="Loan Tools"
              id="basic-nav-dropdown"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item href="https://www.swmc.com/loan-calculators-mortgage-calculators" target="_blank">
                Calculators
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/loan-programs" target="_blank">
                Loan Products
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/blog/" target="_blank">
                Blog
              </NavDropdown.Item>
              <NavDropdown.Item href="https://sunwestmortgage.myloaninfo.com/loanmanager/glossary/" target="_blank">
                Glossary
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/faq" target="_blank">
                FAQ
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/theprocessor" target="_blank">
                The Processor
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/turn-time-commitment" target="_blank">
                Turn Time Commitment
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/angelai/" target="_blank">
                Use AngelAi
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/priceengine/" target="_blank">
                Pricing Engine
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              href="https://www.swmc.com/wholesale-lending"
              id="color-list-parent" target="_blank"
            >
              Wholesale
            </Nav.Link>
            <Nav.Link
              href="https://www.swmc.com/correspondent-lending"
              id="color-list-parent" target="_blank"
            >
              Correspondent
            </Nav.Link>
            <NavDropdown
              title="Client Login"
              id="basic-nav-dropdown"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item href="https://seemyloanstatus.com/ReverseMortgage/jsp/extSeeMyLoanStatusLogin.jsf?loginByUsingView=CMOL" target="_blank">
                Check my loan progress
              </NavDropdown.Item>
              <NavDropdown.Item href="https://sunwestmortgage.myloaninfo.com/loanmanager/login/" target="_blank">
                Make a Payment
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/loss-mitigation" target="_blank">
                Servicing Portal
              </NavDropdown.Item>
              {/* <NavDropdown.Item href="https://www.swmc.com/covid19-relief" target="_blank">
                COVID-19 Relief
              </NavDropdown.Item> */}
              <NavDropdown.Item href="https://www.swmc.com/property-insurance" target="_blank">
                Property Insurance
              </NavDropdown.Item>
              <NavDropdown.Item href="https://www.swmc.com/sunsoft-login" target="_blank">
                ReverseSoft Login
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://www.swmc.com/login" id="color-list-parent" target="_blank">
              Partner Login
            </Nav.Link>
            <div className="alignbtn">
              <Button variant="primary" className="dblue" onClick={handleShowContactModal}>
                Apply Today
              </Button>
            </div>
          <div className="desktop-AccessibilityMenu-show">
            <UserWayAccessibilityMenu color="white" width={40} height={40}/>
          </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="mobile-AccessibilityMenu-show">
        <UserWayAccessibilityMenu color="white" width={40} height={40}/>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showContactModal}
        onHide={handleCloseContactModal}>
        <Modal.Header closeButton>
          <Modal.Title>Apply Today</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BorrowerLeadGenerationTool
            leadSource="NET001"
            loanOfficerUid="CSTC"
            showModalBackBtn={true}
            statesDomain={process.env.REACT_APP_STATE_DOMAIN}
            ref={formRef}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
