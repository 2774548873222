import React, { useEffect, lazy, Suspense } from "react";
import MediaQuery from 'react-responsive';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import Header from "./component/Header";
import ScrollToTop from "./component/ScrollToTop";
import DesktopView from "./component/Desktop/DesktopView";
import MobileView from "./component/Mobile/MobileView";
import CacheBuster from 'react-cache-buster';
import appversion from '../package.json';
// import Footer from "./component/FooterNew/index"
import { CookieSetting } from "cookiesetting-component";

function App() {
  useEffect(() => {
    const script = document.createElement("script");

    script.type = "text/javascript";
    script.src = process.env.REACT_APP_SWMC_ANALYTICS;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <CacheBuster
        currentVersion={appversion.version}
        isEnabled={true} // If false, the library is disabled.
        isVerboseMode={false} // If true, the library writes verbose logs to console.
        // loadingComponent={<Loading />} // If not passed, nothing appears at the time of new version check.
        // If public assets are hosted somewhere other than root on your server.
        metaFileDirectory={process.env.PUBLIC_URL}
      >
        <div> {/* Wrap the children in a single div */}
          {/* Renders the components of mobile view */}
          <MediaQuery maxWidth={991}>
            <Suspense fallback={<div />}>
              <MobileView />
            </Suspense>
          </MediaQuery>
          {/* Renders the components of tablet and desktop view */}
          <MediaQuery minWidth={991.7}>
            <Suspense fallback={<div />}>
              <DesktopView />
            </Suspense>
          </MediaQuery>

          <Suspense fallback={<div />}>
            <ScrollToTop />
          </Suspense>
        </div>
        <CookieSetting
          privacyPolicyLink={process.env.REACT_APP_PRIVACY_POLICY_LINK}
          configUrl={process.env.REACT_APP_COOKIE_SETTING_JSON}
          analytics={process.env.REACT_APP_ANALYTICS_PATH}
        />
      </CacheBuster>
    </div>
  );
}

export default App;